/* eslint-disable */
import axios from 'axios';
const { showAlert, printAlert } = require('./alerts');
const API_BASE_URL = 'https://broneeri.janedaturism.ee/api/v1';

export const editFairBooking = async (
  id,
  fairArea,
  electricity,
  meterAmount,
  specialPrice
) => {
  // console.log(fairArea);
  specialPrice = specialPrice > 0 ? specialPrice : null;
  try {
    const res = await axios({
      method: 'PATCH',
      url: `${API_BASE_URL}/fairbookings/${id}`,
      data: {
        fairArea,
        electricity,
        meterAmount,
        specialPrice
      }
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
      // showAlert('success', 'Laat muudetud');
      location.reload(true);
    }
  } catch (err) {
    console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};

// Edit Client
export const editClient = async (
  id,
  companyName,
  clientName,
  companyCode,
  companyVAT,
  companyAddress
) => {
  // console.log(fairArea);
  try {
    const res = await axios({
      method: 'PATCH',
      url: `${API_BASE_URL}/clients/${id}`,
      data: {
        companyName: companyName,
        name: clientName,
        companyCode: companyCode,
        companyVAT: companyVAT,
        companyAddress: companyAddress
      }
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
      location.reload(true);
    }
  } catch (err) {
    console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};

export const createFairBooking = async (fairId, fairBooking) => {
  // console.log(fairArea);
  try {
    const res = await axios({
      method: 'POST',
      url: `${API_BASE_URL}/fairs/${fairId}/fairbookings`,
      data: {
        client: fairBooking.client,
        fairBooking: fairBooking.fairBooking
      },
      responseType: 'json'
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
        location.href = '/taname';
      //showAlert('success', 'Tellimus edastatud');
      //location.reload(true);
    }
  } catch (err) {
    // showAlert('error', err.response.data.message);
    // console.log('99923', err);
    printAlert('error', err.response.data.error.errors);
  }
};

// setting paid to true
export const fairBookingPaid = async (fairBookingId, paid) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `${API_BASE_URL}/fairbookings/${fairBookingId}`,
      data: {
        paid
      }
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
      // showAlert('success', 'Laat muudetud');
      location.reload(true);
    }
  } catch (err) {
    // console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};

// send invoice
export const sendInvoice = async fairBookingId => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${API_BASE_URL}/fairbookings/send-invoice/${fairBookingId}`
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
      // showAlert('success', 'Laat muudetud');
      location.reload(true);
    }
  } catch (err) {
    // console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};

// send NEW invoice
export const sendNewInvoice = async fairBookingId => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${API_BASE_URL}/fairbookings/send-new-invoice/${fairBookingId}`
    });
    // console.log(res.data);
    if (res.data.status === 'success') {
      // showAlert('success', 'Laat muudetud');
      location.reload(true);
    }
  } catch (err) {
    // console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};

// delete fairBooking
export const deleteFairBooking = async fairBookingId => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/fairbookings/${fairBookingId}`
    });
    // console.log(res.status);
    if (res.status === 204) {
      location.reload(true);
    }
  } catch (err) {
    // console.log(err.response.data.message);
    showAlert('error', err.response.data.message);
  }
};
