/* eslint-disable */

// export const hideAlert = () => {
//   const el = document.querySelector('.alert');
//   if (el) el.parentElement.removeChild(el);
// };
export const hideAlert = () => {
  const elements = document.querySelectorAll('.laada-error');
  elements.forEach(el => {
    el.textContent = '';
  });
};

// type is 'success' or 'error'
export const showAlert = (type, msg) => {
  hideAlert();
  const markup = `<div class="alert-laat alert--${type}">${msg}</div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, 7000);
};

export const printAlert = (type, errors) => {
  // console.log(errors);
  hideAlert();
  const errorArray = Object.values(errors);
  errorArray.forEach(error => {
    const field = error.path;
    const message = error.message;
    const errorElement = document.querySelector(`#${field}-error`);
    errorElement.textContent = message;
  });
};

// export const printAlert = (type, msg) => {
//   const errorFields = msg.split(', ');
//   console.log(errorFields);
//   errorFields.forEach(errorField => {
//     const field = errorField.split(': ')[0];
//     const message = errorField.split(': ')[1];
//     const errorElement = document.querySelector(`#${field}-error`);
//     errorElement.textContent = message;
//   });
// };
