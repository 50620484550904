/* eslint-disable */

const {
  editFairBooking,
  editClient,
  createFairBooking,
  fairBookingPaid,
  sendInvoice,
  sendNewInvoice,
  deleteFairBooking
} = require('./fairManager');
const { login, logout } = require('./login');

//DOM ELEMENTS
const changeBookingForm = document.querySelector('.form--change-booking');
const changeClientForm = document.querySelector('.form--change-client');
const registerBookingForm = document.querySelector(
  '.form--booking-registration'
);
const loginForm = document.querySelector('.form--login');
// const logOutBtn = document.querySelector('.nav__el--logout');

// DELEGATION

if (loginForm)
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });

// if (logOutBtn) logOutBtn.addEventListener('click', logout);

// Edit booking
if (changeBookingForm) {
  // change fairBooking
  changeBookingForm.addEventListener('submit', e => {
    e.preventDefault();

    const fairArea = document.getElementById('inputFairArea').value;
    const fairAreaId = $('#inputFairArea')
      .find(':selected')
      .data('fairarea-id');
    const electricity = document.getElementById('inputElectricity').value;
    const meterAmount = document.getElementById('meterAmount').value;
    const fairBookingId = document.getElementById('fairBookingId').value;
    const specialPrice = document.getElementById('specialPrice').value;
    // alert(fairAreaId);

    editFairBooking(
      fairBookingId,
      fairArea,
      electricity,
      meterAmount,
      specialPrice
    );
  });

  // delete fairBooking
  $('.btn--delete').on('click', function(e) {
    e.preventDefault();
    const fairBookingId = document.getElementById('fairBookingId').value;
    const fairArea = document.getElementById('inputFairArea').value;
    if (fairArea !== 'määramata') {
      alert('Kustutamine ei lähe läbi, kui broneeringu ala on määratud.');
    } else if (confirm('Kas oled kindel, et soovid kustutada?')) {
      // alert(fairBookingId, 'on kustumas');
      deleteFairBooking(fairBookingId);
    }
  });

  // send invoice
  $('.btn--send-invoice').on('click', function(e) {
    e.preventDefault();
    const fairBookingId = document.getElementById('fairBookingId').value;
    if (
      confirm(
        'Kas oled kindel, et soovid saata arve? Sellega kinnitad süsteemis andmed.'
      )
    ) {
      // send invoice
      sendInvoice(fairBookingId);
      alert('arve saadetud');
    }
  });

  // send invoice
  $('.btn--send-new-invoice').on('click', function(e) {
    e.preventDefault();
    const fairBookingId = document.getElementById('fairBookingId').value;
    if (
      confirm(
        'Kas oled kindel, et soovid saata uue arve? Sellega vana arve kustutatakse ja saadetakse uus.'
      )
    ) {
      // send invoice
      sendNewInvoice(fairBookingId);
      alert('arve saadetud');
    }
  });

  // paid fairBooking
  $('.btn--paid').on('click', function(e) {
    e.preventDefault();
    const fairBookingId = document.getElementById('fairBookingId').value;
    // alert('Arve makstud');
    fairBookingPaid(fairBookingId, true);
  });
  // remove payment fromfairBooking
  $('.btn--not-paid').on('click', function(e) {
    e.preventDefault();
    const fairBookingId = document.getElementById('fairBookingId').value;
    // alert('Arve makstud');
    fairBookingPaid(fairBookingId, false);
  });
}

// Edit client pooleli
if (changeClientForm) {
  // change fairBooking
  changeClientForm.addEventListener('submit', e => {
    e.preventDefault();

    const companyName = document.getElementById('companyName').value;
    const clientName = document.getElementById('clientFullName').value;
    const companyCode = document.getElementById('companyCode').value;
    const companyVAT = document.getElementById('companyVat').value;
    const companyAddress = document.getElementById('companyAddress').value;

    const clientId = document.getElementById('clientId').value;

    // alert(clientId);

    editClient(clientId, companyName, clientName, companyCode, companyVAT, companyAddress);
  });
}

// create booking
if (registerBookingForm) {
  registerBookingForm.addEventListener('submit', e => {
    e.preventDefault();

    const companyName = document.getElementById('companyName').value;
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const companyCode = document.getElementById('companyCode').value;
    const companyVAT = document.getElementById('companyVat').value;
    const companyAddress = document.getElementById('companyAddress').value;

    const checkboxes = document.querySelectorAll('.form-check-input');
    let productsSelects = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        productsSelects.push(checkboxes[i].value);
      }
    }
    // console.log(productsSelects);
    const products = document.getElementById('products').value;
    const meterAmount = document.getElementById('meterAmountBooking').value;
    const electricity = document.getElementById('inputElectricitySelect').value;

    // const fairAreaId = $('#inputFairArea')
    //   .find(':selected')
    //   .data('fairarea-id');
    const fairId = document.getElementById('fairId').value;

    const fairBooking = {
      client: {
        name: name,
        companyName: companyName,
        companyVAT: companyVAT,
        companyCode: companyCode,
        companyAddress: companyAddress,
        email: email,
        phone: phone
      },
      fairBooking: {
        meterAmount: meterAmount,
        electricity: electricity,
        products: products,
        productsSelect: productsSelects
      }
    };
    // console.log(fairBooking);
    // alert(fairAreaId);
    createFairBooking(fairId, fairBooking);
  });
}

// CLIENT MODAL EDIT
$('#clientEditModal').on('show.bs.modal', function(event) {
  var button = $(event.relatedTarget); // Button that triggered the modal
  var company = button.data('company'); // Extract info from data-* attributes
  var clientName = button.data('clientname');
  var companyVAT = button.data('company-vat');
  var companyCode = button.data('company-code');
  var companyAddress = button.data('company-address');
  var clientId = button.data('client-id');
  // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
  // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
  var modal = $(this);
  modal.find('.modal-title').text(company);
  modal.find('.modal-body input#companyName').val(company);
  modal.find('.modal-body input#companyCode').val(companyCode);
  modal.find('.modal-body input#companyVat').val(companyVAT);
  modal.find('.modal-body input#companyAddress').val(companyAddress);
  modal.find('.modal-body input#clientFullName').val(clientName);
  modal.find('.modal-body input#clientId').val(clientId);
});
// CLIENT MODAL EDIT END

// BOOKING MODAL EDIT
$('#bookingEditModal').on('show.bs.modal', function(event) {
  var button = $(event.relatedTarget); // Button that triggered the modal
  var company = button.data('company'); // Extract info from data-* attributes
  var clientName = button.data('clientname');
  var meterAmount = button.data('meteramount');
  var fairArea = button.data('fairarea');
  var fairElectricity = button.data('fairelectricity');
  var invoiceNumber = button.data('invoicenr');
  var invoicePaid = button.data('paid');
  var fairId = button.data('fairbooking-id');
  var specialPrice = button.data('specialprice');
  // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
  // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
  var modal = $(this);
  modal.find('.modal-title').text(company);
  modal.find('.modal-body input#company').val(company);
  modal.find('.modal-body input#clientName').val(clientName);
  modal.find('.modal-body input#meterAmount').val(meterAmount);
  modal.find('.modal-body input#fairBookingId').val(fairId);
  if (specialPrice > 0) {
    modal.find('.modal-body input#specialPrice').val(specialPrice);
  } else {
    modal.find('.modal-body input#specialPrice').val('');
  }

  // make change button invisible if invoice is already created
  modal.find('.btn--not-paid').hide();
  if (invoiceNumber !== 'LT-000000') {
    // modal.find('.btn--change').hide();
    modal.find('.btn--send-invoice').hide();
    modal.find('.btn--send-new-invoice').show();
    // modal.find('.btn--delete').hide(); // make booking with invoice deletable
    modal.find('.btn--paid').show();
    // modal.find('#meterAmount').prop('disabled', true);
    // modal.find('#inputFairArea').prop('disabled', true);
    // modal.find('#inputElectricity').prop('disabled', true);
    // modal.find('#specialPrice').prop('disabled', true);
    if (invoicePaid) {
      modal.find('.btn--not-paid').show();
      modal.find('.btn--paid').hide();
    } else {
      modal.find('.btn--not-paid').hide();
    }
  } else {
    // case when invoice is not created
    modal.find('.btn--change').show();
    modal.find('.btn--send-invoice').show();
    modal.find('.btn--send-new-invoice').hide();
    modal.find('.btn--paid').hide();
    modal.find('#meterAmount').prop('disabled', false);
    modal.find('#inputFairArea').prop('disabled', false);
    modal.find('#inputElectricity').prop('disabled', false);
    modal.find('#specialPrice').prop('disabled', false);
    // if area is selected delete button is disabled
    if (fairArea !== 'määramata') {
      modal.find('.btn--delete').hide();
      modal.find('.btn--send-invoice').show();
    } else {
      modal.find('.btn--delete').show();
      modal.find('.btn--send-invoice').hide();
    }
  }

  // Change modal fairArea select list value to previously declared area
  modal
    .find('.modal-body #inputFairArea option')
    .removeAttr('selected')
    .each(function() {
      // console.log($(this).val());
      // console.log(fairArea);
      if ($(this).val() == fairArea) {
        $(this)
          .prop('selected', true)
          .change();
      }
    });

  // Change modal fairElectricity select list value to previously declared value
  modal
    .find('.modal-body #inputElectricity option')
    .removeAttr('selected')
    .each(function() {
      // console.log($(this).val());
      // console.log(fairElectricity);
      if ($(this).val() == fairElectricity) {
        $(this)
          .prop('selected', true)
          .change();
      }
    });
});
// BOOKING MODAL EDIT END

// Modal Create
$('#bookingCreateModal').on('show.bs.modal', function(event) {
  var button = $(event.relatedTarget); // Button that triggered the modal
  var fairId = button.data('fair');

  var modal = $(this);
  // modal.find('.modal-title').text(fairId);
  // modal.find('.modal-body input#company').val(company);
  modal.find('.modal-body input#fairId').val(fairId);

  // var smth = $('#inputFairArea option')
  //   .removeAttr('selected')
  //   .filter(`[value="määramata"]`);
  // console.log(smth.val());
  modal
    .find('.modal-body #inputFairArea option')
    .removeAttr('selected')
    .each(function() {
      // console.log($(this).val());
      // console.log(fairArea);
      if ($(this).val() == fairArea) {
        $(this)
          .prop('selected', true)
          .change();
      }
    });
});
